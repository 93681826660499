import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { company } from '~/company/Company';
import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';
import { useGlobal } from '~/hooks/useGlobal';
import { useStores } from '~/hooks/useStore';
import { catalogStore } from '~/stores/CatalogStore';
import { Category } from '~/stores/CategoriesStore';
import { userStore } from '~/stores/UserStore';

import Icon from '../../Icon/Icon';
import SwiperIcon from '../../Swiper/SwiperIcon';

import styles from './HeaderMenu.module.scss';

type Props = {
  hideAllCategories?: boolean;
};

const HeaderMenu = observer(({ hideAllCategories }: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useGlobal();
  const { handleClickCategory } = useCategoryAnalytics();
  const { shopCategories } = useStores();

  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(true);
  const swiperRef = useRef<SwiperRef>(null);

  const startShoppingSubcategories = shopCategories.list.reduce((acc, item) => {
    return [...acc, ...(item ? item.subcategory : [])];
  }, [] as Category[]);

  useEffect(() => {
    // quick-fix need to fix container and use native navigation
    setTimeout(() => {
      if (!swiperRef.current?.swiper.isBeginning) {
        setIsBeginning(false);
      }
      if (!swiperRef.current?.swiper.isEnd) {
        setIsEnd(false);
      }
    }, 1000);
  }, []);

  const checkIconsVisible = () => {
    setIsBeginning(Boolean(swiperRef.current?.swiper.isBeginning));
    setIsEnd(Boolean(swiperRef.current?.swiper.isEnd));
  };

  return (
    <>
      <div className={classes(['content-layout', styles.container])}>
        <div className={styles.header_menu} data-company={company.name}>
          {!hideAllCategories && (
            <div className={styles.with_line}>
              <Link to="/shop" className={styles.header_menu__item}>
                <Icon type="shop" size={20} />
                {t('allCategories')}
              </Link>
              <div className={styles.header_menu__line} />
            </div>
          )}

          {!isMobile && !isBeginning && (
            <SwiperIcon
              position="left"
              variant="white"
              className={classes([
                styles.icon_swipe,
                styles.button_circle_arrow,
              ])}
              iconClassName={classes([styles.icon])}
              onClick={() => {
                swiperRef.current?.swiper.slidePrev();
                checkIconsVisible();
              }}
            />
          )}
          <Swiper
            spaceBetween={20}
            slidesPerGroup={4}
            slidesPerView="auto"
            ref={swiperRef}
            onSlideChange={checkIconsVisible}
            className={styles.swiper}
            dir={userStore.dir}
            key={userStore.dir}
          >
            {startShoppingSubcategories.map(({ id, name }) => (
              <SwiperSlide className={styles.swiper_slide} key={id}>
                <Link
                  key={id}
                  to={`/c/${id}`}
                  className={classes([styles.header_menu__item])}
                  // TODO: makes extra render in the catalog.
                  // onMouseOver={() => handleToOpen(String(id))}
                  onClick={() => {
                    handleClickCategory(id, 'header')();
                    catalogStore.closeCategoryShutter();
                  }}
                >
                  {name}
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
          {!isMobile && !isEnd && !isBeginning && (
            <SwiperIcon
              position="right"
              variant="white"
              className={classes([
                styles.icon_swipe,
                styles.button_circle_arrow,
              ])}
              iconClassName={classes([styles.icon])}
              onClick={() => {
                swiperRef.current?.swiper.slideNext();
                checkIconsVisible();
              }}
            />
          )}
        </div>
      </div>
    </>
  );
});

export default HeaderMenu;
