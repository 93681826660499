import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import 'swiper/css';

import { company } from '~/company/Company';
import { getCloudflareUrl } from '~/components/CloudflareResponsiveImage/utils';
import Swiper from '~/components/Swiper/Swiper';
import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';
import { useGlobal } from '~/hooks/useGlobal';
import { Category } from '~/stores/CategoriesStore';

import CategoryBlockTitle from '../CategoryBlockTitle';
import { createCategoryUrl } from '../utils';

import styles from './CategoriesCarouselSquareThreeWBlock.module.scss';

interface CategoriesCarouselSliderThreeWBlockProps {
  category: Category;
}

const CategoriesCarouselSliderThreeWBlock = ({
  category,
}: CategoriesCarouselSliderThreeWBlockProps) => {
  const { isMobile } = useGlobal();
  const { handleClickCategory } = useCategoryAnalytics();

  const cfSrcForImage = (src: string) =>
    getCloudflareUrl(src, company.config.cloudflareImages.domain, {
      width: 285,
      height: 380,
    });

  return (
    <div className={classes([styles.container])}>
      <CategoryBlockTitle
        text={category.name}
        className={styles.title}
        categoryId={category.id}
      />
      <div
        className={classes([
          styles.desktopContainer,
          'banner-carousel carousel',
        ])}
      >
        <Swiper
          sliderClassName={styles.items}
          slideClassName={styles.item}
          spaceBetween={isMobile ? 12 : 35}
          slidesPerViewProp="auto"
          scrollAuto
          isPagination
          slidesOffsetAfter={16}
          slidesOffsetBefore={16}
        >
          {category.subcategory.map(
            ({ name, previewImage, id, previewText }, index) => (
              <Link
                onClick={handleClickCategory(id)}
                to={createCategoryUrl({ id })}
                className={styles.link}
                key={index}
              >
                <div className={styles.item}>
                  {previewImage ? (
                    <img src={cfSrcForImage(previewImage)} />
                  ) : (
                    <div className={styles.emptyImg} />
                  )}
                  <div className={styles.itemTitle}>{previewText || name}</div>
                </div>
              </Link>
            ),
          )}
        </Swiper>
      </div>
    </div>
  );
};

export default observer(CategoriesCarouselSliderThreeWBlock);
