import { format } from 'date-fns-tz';
import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { JSX, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import { useDateLang } from '~/hooks/useDateLang';
import { useGlobal } from '~/hooks/useGlobal';
import { normalizeOrderId } from '~/utils/normalizeOrderId';

import { TrackingMap } from '../TrackingMap';

import { DELIVERY_INDICATORS, TRACKING_MAP_API_URL } from './constants';
import styles from './DeliveringStatus.module.scss';
import { IDeliveringStatusProps } from './interfaces';

const DeliveringStatus = observer(
  (props: IDeliveringStatusProps): JSX.Element => {
    const { order, className } = props;
    const { t } = useTranslation();
    const { locale } = useDateLang();

    const { isTablet, isMobile } = useGlobal();
    const isTabletWidth = isTablet || isMobile;

    const formatDate = (date: Date, formatString: string) => {
      return format(date, formatString, {
        timeZone: company.config.timeZone,
        locale,
      });
    };

    const minDate = new Date(order.sla.deliver_by_min);
    const maxDate = new Date(order.sla.deliver_by_max);

    const mapInPortal = useMemo(() => {
      const mapWrapper = (
        <div className={styles.map}>
          {order.public_status === 'in_delivery' ? (
            <iframe
              className={styles.trackingIframe}
              frameBorder={0}
              title="Courier tracking"
              src={`${TRACKING_MAP_API_URL}?order_id=${normalizeOrderId(
                order.order_id,
              )}`}
            />
          ) : (
            <TrackingMap
              address={order.address}
              warehouseCoords={{
                lat: order.warehouse_location_latitude,
                lng: order.warehouse_location_longitude,
              }}
              defaultZoom={company.config.map.zoom}
            />
          )}
        </div>
      );

      if (!isTabletWidth) {
        return mapWrapper;
      }
      return createPortal(mapWrapper, document.body);
    }, [order, isTabletWidth]);

    return (
      <section
        className={classes([styles.root, className])}
        data-company={company.name}
      >
        <div className={styles.header}>
          <div className={styles.header__line}>
            <div className={styles.title}>{t('phrases:estTimeOfDelivery')}</div>
            <div className={styles.timeWrapper}>
              <span className={styles.time}>
                {formatDate(minDate, 'HH:mm')}
              </span>
              <span>&nbsp;</span>
              <span className={styles.halfDay}>
                {formatDate(minDate, 'aaa')}
              </span>
              <span className={styles.timeDash}>&nbsp;&mdash;&nbsp;</span>
              <span className={styles.time}>
                {formatDate(maxDate, 'HH:mm')}
              </span>
              <span>&nbsp;</span>
              <span className={styles.halfDay}>
                {formatDate(maxDate, 'aaa')}
              </span>
            </div>
          </div>
          <div className={styles.date}>
            {formatDate(minDate, 'eee, dd LLLL')}
          </div>
        </div>
        <div className={styles.mapWrapper}>
          <div className={styles.track}>
            {DELIVERY_INDICATORS.map((indicator, idx) => {
              const actualStatusIndex = DELIVERY_INDICATORS.indexOf(
                order.public_status,
              );
              const isLast = idx === DELIVERY_INDICATORS.length - 1;

              return (
                <div
                  className={classes([
                    styles.item,
                    {
                      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
                      [styles.passed]: actualStatusIndex < idx,
                      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
                      [styles.active]: indicator === order.public_status,
                    },
                  ])}
                  key={indicator}
                >
                  {isLast ? (
                    <div className={styles.iconWrapper}>
                      <div className={styles.icon} />
                    </div>
                  ) : (
                    <div className={styles.icon} />
                  )}
                  <div className={styles.text}>
                    {t(`orderStatus:${indicator}.label`)}
                  </div>
                </div>
              );
            })}
          </div>
          {mapInPortal}
        </div>
      </section>
    );
  },
);

export default DeliveringStatus;
