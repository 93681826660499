import { t } from 'i18next';

import {
  ProductPropertiesKeyAPI,
  ProductPropertiesAPI,
  ProductPropertiesTypeAPI,
  Variant,
} from '~/api/Catalog';
import { CartItem } from '~/stores/CatalogStore';
import { Offer, Product } from '~/stores/CategoriesStore';
import { mainStore } from '~/stores/MainStore';

import {
  ProductNutrition,
  ProductProperties,
  ProductPropertiesValue,
  ProductFilterChoice,
} from './interface';

export function isProductBundle(item: Offer | Product): item is Product {
  return Boolean((item as Product).productType === 'bundle');
}

/**
 * Precalculate some fields
 * */
export function calcProductOfferFields<T extends Offer | CartItem>(
  offer: Mutable<T>,
): T {
  offer.priceFormatted = mainStore.convertPenceToPounds(offer.price);
  offer.discountPriceFormatted = mainStore.convertPenceToPounds(
    offer.discountPrice,
  );
  offer.promoQuantityDiscountPriceFormatted = mainStore.convertPenceToPounds(
    offer.promoQuantityDiscountPrice,
  );
  if (offer.discountPrice) {
    const discount = mainStore.calcDiscountPercentage(
      offer.price,
      offer.discountPrice,
    );
    offer.discountPercentage = discount ? `-${discount}%` : '';
  } else {
    offer.discountPercentage = '';
  }
  if (!offer.pricePerUnit && offer.properties) {
    const { product_size, uom } = offer.properties;
    const items_per_outer_retail_pack = parseInt(
      (offer.properties.items_per_outer_retail_pack || '0') as string,
      10,
    );
    if (!items_per_outer_retail_pack || !product_size || !uom) {
      offer.pricePerUnit = '1x';
    } else {
      offer.pricePerUnit =
        items_per_outer_retail_pack === 1
          ? ''
          : `${items_per_outer_retail_pack}×`;
      offer.pricePerUnit += `${product_size}${
        (uom.value as string).includes('pack') ? ' ' : ''
      }${uom}`;
    }
  }
  /*if (offer.bundle_products && offer.bundle_products.length) {
    offer.bundle_products = offer.bundle_products.map(
      product => this.calcProductFields(product));
  }*/
  return offer;
}

export function filterNutritionFields(
  props: ProductPropertiesValue[],
): ProductPropertiesValue[] {
  return props.filter(
    (p) =>
      ![
        'kcal',
        'energy',
        'fat',
        'fat_saturates',
        'carbohydrates',
        'sugar',
        'proteins',
        'salt',
        'fibre',
        'fiber',
      ].includes(p.code),
  );
}
/**
 * Parse product nutritions
 * */
export function parseNutrition(
  props: ProductPropertiesValue[],
): ProductNutrition[] {
  const nutrition: ProductNutrition[] = [];
  if (!props) {
    return nutrition;
  }
  const nutritionFields: {
    RI: number | null;
    maxValue?: number;
    minValue?: number;
    alias?: string;
    name: ProductPropertiesKeyAPI;
  }[] = [
    {
      name: 'kcal',
      RI: 2000,
      alias: 'energy',
    },
    {
      name: 'fat',
      RI: 70,
      minValue: 3,
      maxValue: 10,
    },
    {
      name: 'fat_saturates',
      RI: 20,
      minValue: 1.5,
      maxValue: 5,
    },
    {
      name: 'carbohydrates',
      RI: 260,
    },
    {
      name: 'sugar',
      RI: 30,
      minValue: 5,
      maxValue: 15,
    },
    {
      name: 'proteins',
      RI: 50,
    },
    {
      name: 'salt',
      RI: 6,
      minValue: 0.6,
      maxValue: 1.5,
    },
    {
      name: 'fibre',
      RI: 30,
      alias: 'fiber',
    },
  ];
  nutritionFields.forEach(({ name, alias, RI, minValue, maxValue }) => {
    const property = props.find((p) => p.code === name || p.code === alias);
    if (!property) {
      return;
    }
    const value = mainStore.toFloat(property.value as number);
    let ratio = '-';
    let className = '';
    if (RI !== null) {
      ratio = Math.round((value / RI) * 100) + ' %';
    }
    if (minValue !== undefined && value <= minValue) {
      className = 'low';
    } else if (maxValue !== undefined && value > maxValue) {
      className = 'high';
    } else if (maxValue !== undefined && minValue !== undefined) {
      className = 'medium';
    }
    nutrition.push({
      name,
      value:
        value +
        (name !== 'kcal'
          ? t('nutritionTableNames:gram')
          : t('nutritionTableNames:kcal')),
      additionalValue:
        name === 'kcal'
          ? mainStore.toFloat(value * 4.184).toFixed(0) +
            t('nutritionTableNames:kJ')
          : '',
      ratio,
      className,
    });
  });
  return nutrition;
}

export function parseVariants(data: Variant[]): ProductProperties {
  return data.reduce((properties, { value, property }, order) => {
    properties[property as ProductPropertiesKeyAPI] = {
      order,
      value,
      type: '',
      name: '',
      choices: [],
      isOfferVariants: false,
      sorting: order,
      code: '',
    };

    return properties;
  }, {} as ProductProperties);
}

/**
 * Parse formatted properties fields
 * */
export function parseProperties(
  props: ProductPropertiesAPI[],
): [ProductProperties, ProductProperties] {
  const properties: ProductProperties = {};
  const selectableProperties: ProductProperties = {};

  if (!props.length) {
    return [properties, selectableProperties];
  }

  const types: ProductPropertiesTypeAPI[] = [
    'checkboxGroup',
    'radioGroup',
    'selector',
    'tagList',
  ];
  for (let order = 0; order < props.length; order++) {
    // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
    const {
      code,
      value,
      values = '',
      type,
      name,
      isOfferVariants,
      choices = [],
      sorting,
    } = props[order];
    let val: string | number | boolean | string[] = value ?? '';
    if (type === 'toggle' && typeof value === 'string') {
      val = value === 'true';
    }
    if (types.includes(type)) {
      val = values;
    }

    const sortedChoice = choices.sort(
      (a: ProductFilterChoice, b: ProductFilterChoice) => a.sorting - b.sorting,
    );

    properties[code as ProductPropertiesKeyAPI] = {
      code,
      name,
      value: val,
      order,
      type,
      choices: sortedChoice,
      isOfferVariants,
      sorting,
      code,
    };

    if (isOfferVariants) {
      selectableProperties[code as ProductPropertiesKeyAPI] = {
        name,
        value,
        order,
        type,
        choices: sortedChoice,
        isOfferVariants,
        sorting,
        code,
      };
    }
  }

  return [properties, selectableProperties];
}
