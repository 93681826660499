import { addHours, isToday } from 'date-fns';
import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { company } from '~/company/Company';
import { BundleBadge } from '~/components/BundleBadge';
import DeliveryDateLabel from '~/components/DeliveryDateLabel/DeliveryDateLabel';
import { MultibuyBadge } from '~/components/MultibuyBadge';
import { useProductCard } from '~/components/ProductCard/hooks/useProductCard';
import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';
import { useGlobal } from '~/hooks/useGlobal';
import { Product, ProductState } from '~/stores/CategoriesStore';
import { formatPrice, formatPriceWithCurrency } from '~/utils/formaters';

import CloudflareResponseImage from '../CloudflareResponsiveImage/CloudflareResponseImage';
import { defaultSrcSetParams } from '../CloudflareResponsiveImage/constants';
import Counter from '../Counter';
import ExpressDeliveryBadge from '../ExpressDeliveryBadge/ExpressDeliveryBadge';
import Icon from '../Icon/Icon';
import { PropVariants } from '../PropVariants/PropVariants';
import TooltipSelector from '../SmallSelector/SmallSelector';

import styles from './ProductCard.module.scss';
import { formatVolume, pluralizePcLabel } from './utils';
import ProductCardVariant1 from './Variant1/ProductCard';

interface ProductCardProps {
  product: Product;
  className?: string;
  isRatingShown?: boolean;
  hideButton?: boolean;
  productState?: ProductState;
}

const ProductCard = ({
  product,
  className,
  isRatingShown = true,
  hideButton = false,
  productState,
}: ProductCardProps) => {
  const { t } = useTranslation();
  const { handleClickProduct } = useCategoryAnalytics();
  const { isMobile } = useGlobal();
  const {
    currentItem,
    volumes,
    limit,
    image,
    leadTime,
    oldPrice,
    currentPrice,
    cartItemsCount,
    isMultiBuy,
    isExpress,
    isBackorderAvailable,
    isBundle,
    isFavorite,
    productHasManyVariants,
    colorVariants,
    selectOffer,
    toggleFavorite,
    changeCount,
    addMultiBuyProductToCart,
    openSelectSKUModal,
  } = useProductCard(
    productState?.data ?? product,
    productState?.selectedOffer,
  );

  const rating = useMemo(() => {
    return product.ratingAverage ? (
      <div className="product-card__rated">
        <Icon type="star-fill" size={12} />
        {product.ratingAverage}
      </div>
    ) : (
      <div className={styles.no_rating}>
        <Icon type="star-fill" size={12} className={styles.no_rating__star} />
        {t('noRatingShort')}
      </div>
    );
  }, [product.ratingAverage]);

  const addButtonHandler = () => {
    productHasManyVariants
      ? openSelectSKUModal()
      : changeCount(cartItemsCount + 1, 'add');
  };

  return (
    <div className={classes(['product-card', className])}>
      <div
        className={classes([
          'product-card__img-wrap',
          isMultiBuy && styles.multibuyImageWr,
        ])}
      >
        <CloudflareResponseImage
          src={currentItem.images[0] ?? image ?? ''}
          srcParams={{ width: 210, height: 274 }}
          srcSetParams={defaultSrcSetParams}
          alt=""
          className="product-card__img"
          loading="lazy"
        />
        <Link
          onClick={handleClickProduct(product)}
          to={`/p/${product?.slug}`}
          state={{ offerSku: currentItem.sku }}
          className="link-abs"
        />

        {isExpress ? (
          <ExpressDeliveryBadge />
        ) : isBackorderAvailable ? (
          <DeliveryDateLabel
            className={styles.deliveryDateLabel}
            deliveryDate={
              isToday(addHours(new Date(), leadTime))
                ? null
                : addHours(new Date(), leadTime)
            }
          />
        ) : null}
        {isBundle && <BundleBadge className={styles.bundleBadge} />}
        {isMultiBuy && (
          <MultibuyBadge
            value={currentItem.promoRequiredQuantity}
            className={styles.multibuyCounter}
            onClick={addMultiBuyProductToCart}
          />
        )}
        <button
          className={classes([
            'button _no-padding product-card__fav',
            isFavorite && '_fill',
          ])}
          onClick={toggleFavorite}
        >
          <Icon type={isFavorite ? 'fav-fill' : 'fav'} size={20} />
        </button>
      </div>
      <div className="product-card__body">
        <div className="product-card__info">
          {isRatingShown && rating}
          {!hideButton &&
            (isBundle ? (
              <p className="product-card__info-volume">{pluralizePcLabel(1)}</p>
            ) : (
              (volumes?.length > 1 && (
                <TooltipSelector
                  onChange={selectOffer}
                  options={volumes}
                  id={currentItem.sku || ''}
                  side={isRatingShown ? 'left' : 'right'}
                />
              )) || (
                <p className="product-card__info-volume">
                  {formatVolume(currentItem.properties)}
                </p>
              )
            ))}
        </div>
        {colorVariants.length > 0 && (
          <PropVariants colorVariants={colorVariants} />
        )}
        <div className="product-card__name">
          <Link
            to={`/p/${product.slug}`}
            onClick={handleClickProduct(product)}
            state={{ offerSku: currentItem.sku }}
          >
            {currentItem.name}
          </Link>
        </div>
        <div className="product-card__footer">
          <div className="product-card__price-wrap">
            <div
              className={classes([
                'product-card__price',
                oldPrice && 'good',
                isMultiBuy && styles.multibuyOldPrice,
              ])}
            >
              {formatPriceWithCurrency(+currentPrice)}
              {isMultiBuy ? ' / 1x' : ''}
            </div>
            {oldPrice && (
              <div className="product-card__price _old">
                {formatPrice(+oldPrice)}
              </div>
            )}
          </div>
          {isMultiBuy && (
            <div className={styles.multibuyPriceWr}>
              <div className={styles.multibuyFromRule}>
                {t('phrases:fromPc', {
                  length: pluralizePcLabel(currentItem?.promoRequiredQuantity),
                })}
              </div>
              <div className="product-card__price good">
                {formatPriceWithCurrency(
                  currentItem?.promoQuantityDiscountPriceFormatted || '',
                )}
              </div>
            </div>
          )}
          {!hideButton &&
            (cartItemsCount > 0 ? (
              <Counter
                count={cartItemsCount}
                size={isMobile ? 's' : 'm'}
                onClick={changeCount}
                limit={limit}
              />
            ) : (
              <button
                className={classes([
                  'button _primary product-card__buy',
                  isMobile && '_small',
                  limit === 0 && '_disabled',
                ])}
                onClick={addButtonHandler}
              >
                <Icon type="bag" size={isMobile ? 16 : 24} />
                {limit === 0 ? t('soldOut') : t('addToCart')}
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};

export default company.match({
  citydrinks: observer(ProductCard),
  default: ProductCardVariant1,
});
