import { classes } from 'html-classes';
import { useTranslation } from 'react-i18next';

import { KYCStatus } from '~/api/KYCStatus';
import { company } from '~/company/Company';
import Text from '~/components/Text';
import { VERIFICATION } from '~/constants/verification';
import styles from '~/pages/Cart/Cart.module.scss';
import { catalogStore } from '~/stores/CatalogStore';
import { userStore } from '~/stores/UserStore';
import { formatPriceWithCurrency } from '~/utils/formaters';

import { SubtotalInfoProps } from '../../../types';

import { CartCostItems } from '../../CartCostItems';

import subtotalStyles from './SubtotalInfo.module.scss';

const SubtotalInfo = ({
  isWarehouseClosed,
  isLoading,
  onCheckout,
  onNavigateToCheckout,
  stickyWrapperStyles,
  subtotalWrapperRef,
}: SubtotalInfoProps) => {
  const { t } = useTranslation();

  const renderGoToCheckoutdButton = () => {
    const goToCheckout = (
      <>
        <button
          type="button"
          onClick={onNavigateToCheckout}
          className={classes([
            'button _primary subtotal-info__button',
            styles.goToCheckoutButton,
            (isLoading || isWarehouseClosed) && '_disabled',
          ])}
          disabled={catalogStore.calculationProcess.isLoading}
        >
          {isLoading && <span className="spinner _white" />}
          {t('goToCheckout')}
        </button>
        <p className={styles.termsAndConditionText}>
          {t('phrases:deliveryInfoOnCheckoutPage')}
        </p>
      </>
    );

    if (
      (!company.needVerification ||
        (userStore.isAuthorized &&
          userStore.personalData.kycStatus === KYCStatus.Verified)) &&
      catalogStore.totalCartPrice.minimal_order_value_error?.length &&
      catalogStore.totalCartPrice.minimal_order_value_error[0]
    ) {
      return (
        <>
          <div
            onClick={onCheckout}
            className={classes([
              'button _primary subtotal-info__button _med _disabled _center',
              styles.subtotalInfo__button,
            ])}
          >
            {t('errors:minCartError', {
              amount: formatPriceWithCurrency(
                catalogStore.totalCartPrice.minimal_order_value_error[0]
                  ?.minimalOrderValue / 100,
              ),
            })}
            <br />
            {t('errors:minCartErrorDetails', {
              amount: formatPriceWithCurrency(
                (catalogStore.totalCartPrice.minimal_order_value_error[0]
                  ?.minimalOrderValue -
                  catalogStore.totalCartPrice.minimal_order_value_error[0]
                    ?.orderPriceWithoutDelivery) /
                  100,
              ),
            })}
          </div>
        </>
      );
    }

    if (!company.needVerification) {
      return userStore.isAuthorized ? (
        goToCheckout
      ) : (
        <>
          <div
            onClick={onCheckout}
            className="button _primary subtotal-info__button"
          >
            {t('logInToCheckout')}
          </div>
          <Text size="12" weight="500" className="subtotal-info__additional">
            {t('phrases:verifyIdDescription')}
          </Text>
        </>
      );
    }

    switch (userStore.personalData.kycStatus) {
      case KYCStatus.Verified:
        return goToCheckout;
      case KYCStatus.InProgress:
      case KYCStatus.ResubmissionRequested:
      case KYCStatus.Started: {
        const statusConfig = VERIFICATION[userStore.personalData.kycStatus];
        const isDisabled =
          (userStore.personalData.kycStatus === KYCStatus.InProgress ||
            userStore.personalData.kycStatus === KYCStatus.Started) &&
          '_disabled';
        return (
          <>
            <div
              onClick={onCheckout}
              className={`button _primary subtotal-info__button ${isDisabled}`}
            >
              {statusConfig.title}
            </div>
            <Text size="12" weight="500" className="subtotal-info__additional">
              {statusConfig.description || ''}
            </Text>
          </>
        );
      }
      default:
        return (
          <>
            <div
              onClick={onCheckout}
              className="button _primary subtotal-info__button"
            >
              {userStore.isAuthorized ? t('verifyId') : t('logInToCheckout')}
            </div>
            <Text size="12" weight="500" className="subtotal-info__additional">
              {t('phrases:verifyIdDescription')}
            </Text>
          </>
        );
    }
  };

  return (
    <div className="subtotal-info">
      <div
        className={classes([styles.subtotalWr, subtotalStyles.root])}
        style={stickyWrapperStyles}
        ref={subtotalWrapperRef}
      >
        {catalogStore.selectedItemsTotalCount > 0 ? (
          <>
            <CartCostItems />

            {renderGoToCheckoutdButton()}
          </>
        ) : (
          <>
            <button
              className={classes([
                'button _primary subtotal-info__button _disabled',
                styles.goToCheckoutButton,
              ])}
            >
              {t('goToCheckout')}
            </button>
            <p className={styles.termsAndConditionText}>
              {t('selectProductsToContinue')}
            </p>
          </>
        )}

        {catalogStore.promotionStore.applyPromocodeMeta.isLoading && (
          <div className={subtotalStyles.loader}>
            <span className="spinner" />
          </div>
        )}
      </div>
    </div>
  );
};

export default SubtotalInfo;
