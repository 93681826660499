import { CLOUDFLARE_IMAGE_RESIZE_ENABLED } from '~/api/constants';

import { CompanyConfig } from '../interface';
// TODO: need update!!!
export const ViloConfig: CompanyConfig = {
  id: 'a1afcf22-db07-4c78-8e40-4ada483e1805',
  lang: 'en',
  langs: ['en'],
  name: 'Vilo Gelato',
  currency: 'IDR',
  officialName: 'Vilo Gelato',
  email: 'sales@vilo.id',
  feedbackEmail: 'feedback@vilogelato.com',
  phonePlaceholder: '+62',
  defaultPhoneCode: '+62',
  phoneNumber: '',
  ageRestricted: 18,
  timeZone: 'Asia/Jakarta',
  minimalOrderFeeThreshold: 700,
  address: 'Teaneck Rd, Ridgefield Park, NJ 07660, USA',
  canonical: '',
  warehouse: {
    default: 'Barito Langsat-957359',
    minimalOrderFeeWarehouseCodes: [],
    location: { lat: -6.2439581, lng: 106.7896507 },
  },
  map: {
    center: { lat: -6.2439581, lng: 106.7896507 },
    zoom: 12,
  },
  links: {
    cookie: '',
    deliveryZones: '',
    forRiders: '',
    whatsapp: '',
    facebook: 'https://www.facebook.com/vilogelato',
    instagram: 'https://www.instagram.com/vilogelato/',
    tiktok: '',
    youtube: 'https://www.youtube.com/channel/UCIMlUXO3FiI8WaAJCrkuo9w',
    affiliateProgram: import.meta.env.REACT_APP_AFFILIATE_PROGRAM_LINK,
    legals: {
      cookiePolicy: {
        label: 'cookiePolicy',
        link: '/legals#cookie-policy',
      },
      paymentAndDeliveryPolicy: {
        label: 'paymentAndDeliveryPolicy',
        link: '/legals#payment-and-delivery',
      },
      privacyPolicy: {
        label: 'privacyPolicy',
        link: '/legals#privacy-policy',
      },
      salesAndReturnPolicy: {
        label: 'salesAndReturnPolicy',
        link: '/legals#sales-and-returns-policies-and-consumer',
      },
      generalTradingPolicy: {
        label: 'generalTradingPolicy',
        link: '/legals#terms-of-service',
      },
    },
  },
  referral: {
    code: 'SWIFFTUCDAVIS',
    discount: '15%',
    minBasket: '15',
    downloadAppLink: '',
  },
  points: {
    value: '1=1c.',
    pointsPerUnit: 15,
    icon: 'coins',
  },
  appStoreUrl: {
    ios: '',
    android: '',
  },
  apis: {},
  countryCode: 'ID',
  cloudflareImages: {
    enabled: CLOUDFLARE_IMAGE_RESIZE_ENABLED,
    domain: import.meta.env.REACT_APP_CLOUDFLARE_IMAGE_RESIZE_DOMAIN,
  },
  license: {
    name: 'Vilo Gelato',
    numberPrinary: '',
  },
};
